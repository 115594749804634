import { graphql, useStaticQuery } from "gatsby";
import { AllAppearancesQuery } from "types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAllAppearancesData = () => {
  const {
    allSanityAppearance: { edges },
  } = useStaticQuery<AllAppearancesQuery>(
    graphql`
      query allAppearances {
        allSanityAppearance(sort: { fields: information___date, order: DESC }) {
          edges {
            node {
              ...Appearance
            }
          }
        }
      }
    `
  );

  const allAppearancesData = edges.map(({ node }) => node);

  return allAppearancesData;
};

export default useAllAppearancesData;
