import React from "react";
import { useBlurOnClick } from "hooks";
import { Link } from "gatsby";
import {
  SanityProject,
  SanityWork,
  SanityEphemeron,
  SanityWriting,
} from "types";
import { archiveItemTypesPluralized } from "utils";
import "styles/global/index.scss";
import styles from "./siteLayout.module.scss";

const NavLink: React.FC<{
  to: string;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, to, className, style }) => (
  <Link
    {...(className && { className })}
    {...(style && { style })}
    to={to}
    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void =>
      useBlurOnClick(e)
    }
    activeClassName="current"
  >
    {children}
  </Link>
);

const SiteLayout: React.FC<{
  pageContext?: {
    data: SanityProject | SanityWork | SanityEphemeron | SanityWriting;
  };
}> = ({ children, pageContext, location }) => {
  const archiveItemData = pageContext?.data;
  const archiveItemTitle = archiveItemData?.information?.title;
  const archiveItemYear = archiveItemData?.information?.year;
  const archiveItemType = archiveItemData?.type;

  const onTypeArchivePage =
    location.pathname.split("/")[2] &&
    location.pathname.split("/")[1] === "archive";
  const typeArchivePageName = onTypeArchivePage
    ? location.pathname.split("/")[2].charAt(0).toUpperCase() +
      location.pathname.split("/")[2].slice(1)
    : archiveItemTypesPluralized[archiveItemType]?.upper;
  const typeArchivePageUrl = onTypeArchivePage
    ? location.pathname
    : `/archive/${
        archiveItemTypesPluralized[archiveItemType] &&
        archiveItemTypesPluralized[archiveItemType]?.lower
      }`;

  return (
    <>
      <header className={styles.header}>
        <nav>
          <ul className={styles.linkList}>
            <li className={styles.archiveLink}>
              <NavLink to="/archive">Archive</NavLink>
              {(onTypeArchivePage || archiveItemType) && (
                <>
                  <span className={styles.slash}> / </span>
                  <NavLink
                    className={styles.typeArchivePageLink}
                    to={typeArchivePageUrl}
                    style={{ animationDelay: `40ms` }}
                  >
                    {typeArchivePageName}
                  </NavLink>
                </>
              )}
              {archiveItemTitle &&
                archiveItemYear &&
                archiveItemType &&
                archiveItemType !== "writing" && (
                  <>
                    <span
                      className={styles.slash}
                      style={{ animationDelay: `80ms` }}
                    >
                      {" / "}
                    </span>
                    <h1
                      className={styles.itemTitle}
                      style={{ animationDelay: `160ms` }}
                    >
                      {archiveItemTitle}
                    </h1>
                  </>
                )}
            </li>
            <li className={styles.informationLink}>
              <NavLink to="/information">Information</NavLink>
            </li>
          </ul>
        </nav>
      </header>
      {children}
    </>
  );
};

export default SiteLayout;
