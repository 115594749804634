import { graphql, useStaticQuery } from "gatsby";
import { AllWorksQuery } from "types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAllWorksData = () => {
  const {
    allSanityWork: { edges },
  } = useStaticQuery<AllWorksQuery>(
    graphql`
      query allWorks {
        allSanityWork(sort: { fields: information___date, order: DESC }) {
          edges {
            node {
              ...Work
            }
          }
        }
      }
    `
  );

  const allWorksData = edges.map(({ node }) => node);

  return allWorksData;
};

export default useAllWorksData;
