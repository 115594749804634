import { graphql, useStaticQuery } from "gatsby";
import { AllWritingsQuery } from "types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAllWritingsData = () => {
  const {
    allSanityWriting: { edges },
  } = useStaticQuery<AllWritingsQuery>(
    graphql`
      query allWritings {
        allSanityWriting(sort: { fields: information___date, order: DESC }) {
          edges {
            node {
              ...Writing
            }
          }
        }
      }
    `
  );

  const allWritingsData = edges.map(({ node }) => node);

  return allWritingsData;
};

export default useAllWritingsData;
