import { graphql, useStaticQuery } from "gatsby";
import { AllEphemeraQuery } from "types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAllEphemeraData = () => {
  const {
    allSanityEphemeron: { edges },
  } = useStaticQuery<AllEphemeraQuery>(
    graphql`
      query allEphemera {
        allSanityEphemeron(sort: { fields: information___date, order: DESC }) {
          edges {
            node {
              ...Ephemeron
            }
          }
        }
      }
    `
  );

  const allEphemeraData = edges.map(({ node }) => node);

  return allEphemeraData;
};

export default useAllEphemeraData;
