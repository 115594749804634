const useEase = (): { [key: string]: (x: number) => number } => {
  const inQuad = (x: number): number => {
    return x * x;
  };

  const outQuad = (x: number): number => {
    return 1 - (1 - x) * (1 - x);
  };

  const inOutQuad = (x: number): number => {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
  };

  return { inQuad, outQuad, inOutQuad };
};

export default useEase;
