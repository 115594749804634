/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import sanityClient from "@sanity/client";
import sanityImageUrl from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./richTextWithImage.module.scss";

const client = sanityClient({
  dataset: "production",
  projectId: "jcmgoa34",
  useCdn: true,
});

const builder = sanityImageUrl(client);

const singleImageSerializer = (
  {
    node: {
      image: { asset },
      alt,
      caption,
      fullBleed,
      fullWidth,
    },
  }: {
    node: {
      image: { asset: any };
      alt?: string;
      caption?: string;
      fullBleed?: boolean;
      fullWidth?: boolean;
    };
  },
  key?: string
) => {
  const src = builder
    .image(asset)
    .width(fullWidth || fullBleed ? 1400 : 900)
    .dpr(2)
    .url();
  const className = key
    ? styles.multiImageChild
    : styles[
        fullBleed ? "fullBleed" : fullWidth ? "fullWidth" : "floatedImage"
      ];

  return src ? (
    <figure className={className} {...(key && { key })}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={src} alt={alt} />
      </div>
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  ) : (
    <figure className={className} {...(key && { key })}>
      <div>Not found: {alt}</div>
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
};

const multiImageSerializer = (props: any) => {
  const className = props.node.fullWidth
    ? `${styles.multiImageWrapper} ${styles.fullWidth}`
    : `${styles.multiImageWrapper} ${styles.floatedImage}`;

  return (
    <section className={className}>
      {props.node.images.map((image: any) =>
        singleImageSerializer(
          {
            node: image,
          },
          image._key
        )
      )}
    </section>
  );
};

interface Props {
  content: unknown;
  className?: string;
}

const RichTextWithImage: React.FC<Props> = ({
  content,
  className: customClassName,
}) => {
  const serializers = {
    container: "article",
    types: {
      singleImage: (props: any) => singleImageSerializer(props),
      multiImage: (props: any) => multiImageSerializer(props),
    },
  };
  return (
    <BlockContent
      {...(customClassName
        ? { className: `${customClassName} ${styles.content}` }
        : { className: styles.content })}
      blocks={content}
      dataset="production"
      projectId="jcmgoa34"
      renderContainerOnSingleChild={true}
      serializers={serializers}
    />
  );
};

export default RichTextWithImage;
