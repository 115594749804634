/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAllProjectsData } from "./";
import { useAllWritingsData } from "./";
import { useAllWorksData } from "./";
import { useAllEphemeraData } from "./";
import { useAllAppearancesData } from "./";

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useArchiveData = () => {
  const allProjects = useAllProjectsData();
  const allWritings = useAllWritingsData();
  const allWorks = useAllWorksData();
  const allEphemera = useAllEphemeraData();
  const allAppearances = useAllAppearancesData();

  const allArchiveItems = [
    ...allProjects,
    ...allWritings,
    ...allWorks,
    ...allEphemera,
    ...allAppearances,
  ];

  const sortByDateAsc = (
    a: ArrayElement<typeof allArchiveItems>,
    b: ArrayElement<typeof allArchiveItems>
  ): -1 | 0 | 1 => {
    const dateA = new Date(a.information!.utcDate as Date);
    const dateB = new Date(b.information!.utcDate as Date);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
  };

  allArchiveItems.sort((a, b) => sortByDateAsc(a, b)).reverse();

  const archiveData: {
    [key: number]: ArrayElement<typeof allArchiveItems>[];
  } = {};

  const archiveYears: number[] = [];

  allArchiveItems.forEach((item) => {
    const year = item.information!.year as number;

    if (!archiveData[year]) {
      archiveData[year] = [item];
      archiveYears.push(year);
    } else {
      archiveData[year].push(item);
    }
  });

  return { archiveData, archiveYears };
};

export default useArchiveData;
