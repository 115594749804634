export const archiveItemTypesPluralized: {
  [key: string]: { [key: string]: string };
} = {
  work: {
    lower: "work",
    upper: "Work",
  },
  project: {
    lower: "projects",
    upper: "Projects",
  },
  ephemeron: {
    lower: "ephemera",
    upper: "Ephemera",
  },
  writing: {
    lower: "writings",
    upper: "Writings",
  },
  appearance: {
    lower: "appearances",
    upper: "Appearances",
  },
};
