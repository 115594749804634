import { graphql, useStaticQuery } from "gatsby";
import { AllProjectsQuery } from "types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAllProjectsData = () => {
  const {
    allSanityProject: { edges },
  } = useStaticQuery<AllProjectsQuery>(
    graphql`
      query allProjects {
        allSanityProject(sort: { fields: information___date, order: DESC }) {
          edges {
            node {
              ...Project
            }
          }
        }
      }
    `
  );

  const allProjectsData = edges.map(({ node }) => node);

  return allProjectsData;
};

export default useAllProjectsData;
